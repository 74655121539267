<script lang="ts">
	import { css } from 'styled-system/css';
</script>

<footer
	class={css({
		paddingTop: '1.5rem',
		paddingBottom: '1rem',
		display: 'grid',
		placeItems: 'end center',
		textAlign: 'center',
	})}
>
	<p>
		2023-2024, created by Haruhito Aso.
		<br class={css({ md: { display: 'none' } })} />
		with
		<a href="https://kit.svelte.dev">SvelteKit </a>
		,
		<a href="https://panda-css.com">Panda CSS </a>
		,
		<a href="https://mdsvex.com">mdsvex </a>
	</p>
</footer>
