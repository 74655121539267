<script lang="ts">
	import type { LayoutServerData } from './$types';
	import '../app.css';
	import ThemeToggle from '$lib/features/theme/components/ThemeToggle.svelte';
	import Footer from '$lib/features/shared/components/Footer.svelte';
	import { grid, gridItem } from 'styled-system/patterns';
	import { css } from 'styled-system/css';

	export let data: LayoutServerData;
</script>

<div
	class={grid({
		color: 'text',
		bg: 'bg',
		minHeight: '100vh',
		gridTemplateColumns: {
			base: '1rem auto auto 1rem',
			md: '1fr 0fr 10fr 1fr',
		},
		gridTemplateRows: '3.4rem auto auto',
		columnGap: '0rem',
	})}
>
	<div
		class={gridItem({
			w: '100%',
			colStart: 2,
			colEnd: 2,
			rowStart: 1,
			rowEnd: 1,
			textStyle: 'home',
			color: 'link',
			justifySelf: 'start',
		})}
	>
		<a href="/" class={css({ textDecoration: 'none' })}> hmnkpl.com </a>
	</div>
	<div
		class={gridItem({
			colStart: 3,
			colEnd: 4,
			rowStart: 1,
			rowEnd: 1,
			color: 'text',
			justifySelf: 'end',
			alignSelf: 'center',
		})}
	>
		<ThemeToggle bind:isDark={data.isDark} />
	</div>
	<div class={gridItem({ w: '100%', colStart: 2, colEnd: 4, rowStart: 2, rowEnd: 2 })}>
		<slot />
	</div>
	<div
		class={gridItem({
			justifyContent: 'center',
			colStart: 2,
			colEnd: 4,
			rowStart: 3,
			rowEnd: 3,
		})}
	>
		<Footer />
	</div>
</div>
