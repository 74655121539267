import { updateOnBrowser } from './theme';

export const handleSubmit = (isDark: boolean) => {
	fetch('/theme', {
		method: 'PUT',
		headers: { 'content-type': 'application-json' },
		body: JSON.stringify({ isDark: !isDark }),
	})
		.then((res) => res.json())
		.then((data) => {
			updateOnBrowser(data.isDark);
		});
};
