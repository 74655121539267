import { browser } from '$app/environment';

const attr = 'data-color-mode';

export const replaceTarget = '%theme%';

export const cookieKey = 'theme';

export enum Theme {
	Light = 'light',
	Dark = 'dark',
}

export const getWindowSettings = () => {
	if (browser) {
		return convertToTheme(window.matchMedia('(prefers-color-scheme: dark)').matches);
	}
	return null;
};

export const updateOnBrowser = (isDark: boolean) => {
	const doc = browser ? document.body : null;
	if (doc == null) {
		return;
	}

	doc.removeAttribute(attr);
	doc.setAttribute(attr, convertToTheme(!isDark));
};

export const convertToTheme = (isDark: boolean) => (isDark ? Theme.Dark : Theme.Light);
